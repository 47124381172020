import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';
import { defineModule, nextTick } from '../../utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElement:
    document.querySelector<HTMLButtonElement>('.navbar__toggler'),
});

const toggleNavbar = (force?: boolean) => {
  const { navbarElement, navbarTogglerElement } = getElements();
  if (!navbarElement || !navbarTogglerElement) return;

  force = force ?? !hasOpenOverlay(Overlay.MENU);

  toggleOverlay(
    Overlay.MENU,
    navbarElement.classList.toggle('navbar--open', force),
  );

  navbarTogglerElement.ariaExpanded = String(hasOpenOverlay(Overlay.MENU));
};

const onNavbarTogglerClick = () => toggleNavbar();

const navbarBreakpointChecker = () => {
  const { navbarElement, navbarTogglerElement } = getElements();
  if (!navbarElement || !navbarTogglerElement) return;

  toggleOverlay(Overlay.MENU, false);

  navbarTogglerElement.ariaExpanded = hasOpenOverlay(Overlay.MENU)
    ? 'true'
    : 'false';

  navbarElement.classList.add('navbar--no-transitions');
  navbarElement.classList.remove('navbar--open');
  nextTick(() => {
    navbarElement.classList.remove('navbar--no-transitions');
  });
};

export default defineModule(
  () => {
    const { navbarElement, navbarTogglerElement } = getElements();
    if (!navbarElement || !navbarTogglerElement) return;

    navbarTogglerElement.addEventListener('click', onNavbarTogglerClick, {
      passive: true,
    });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbarElement, navbarTogglerElement } = getElements();
    if (!navbarElement || !navbarTogglerElement) return;

    toggleNavbar(false);

    navbarTogglerElement.removeEventListener('click', onNavbarTogglerClick);

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
