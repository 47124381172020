import type { Splide } from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const splides: Splide[] = [];

export default defineModule(
  async () => {
    const teamContentBlockElements = document.querySelectorAll<HTMLElement>(
      '.content-block.content-block--team',
    );
    if (!teamContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    teamContentBlockElements.forEach((teamContentBlockElement) => {
      const splideElement =
        teamContentBlockElement.querySelector<HTMLElement>('.splide');
      if (!splideElement) return;

      splides.push(
        new Splide(splideElement, {
          type: 'loop',
          gap: '1rem',
          arrows: false,
          autoWidth: false,
          updateOnMove: true,
          focus: 'center',
          omitEnd: true,
          mediaQuery: 'min',
          breakpoints: {
            [BREAKPOINTS.lg]: {
              destroy: true,
            },
          },
        }).mount(),
      );
    });
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
