import type Plyr from 'plyr';
import { createElement, defineModule } from '@/js/utils/helpers';

const players: Plyr[] = [];

const getVideoTitle = async (player: Plyr): Promise<string | undefined> => {
  if (player.provider === 'youtube') {
    // @ts-ignore Private property
    return player.config.title;
  }
  if (player.provider === 'vimeo') {
    return player.embed?.getVideoTitle();
  }

  return undefined;
};

export default defineModule(
  async () => {
    const videoContentBlockElements = document.querySelectorAll<HTMLElement>(
      '.content-block.content-block--video',
    );
    if (!videoContentBlockElements.length) return;

    const { default: Plyr } = await import('plyr');

    videoContentBlockElements.forEach((videoContentBlockElement) => {
      const plyrElement =
        videoContentBlockElement.querySelector<HTMLElement>('[data-plyr]');
      if (!plyrElement) return;

      const player = new Plyr(plyrElement, {
        i18n: {
          restart: 'Herstarten',
          rewind: 'Spoel {seektime}s terug',
          play: 'Afspelen',
          pause: 'Pauzeren',
          fastForward: 'Spoel {seektime}s vooruit',
          seek: 'Zoek',
          seekLabel: '{currentTime} van {duration}',
          played: 'Gespeeld',
          buffered: 'Gebufferd',
          currentTime: 'Huidige tijd',
          duration: 'Looptijd',
          volume: 'Volume',
          mute: 'Dempen',
          unmute: 'Dempen opheffen',
          enableCaptions: 'Schakel ondertiteling in',
          disableCaptions: 'Schakel ondertiteling uit',
          download: 'Download',
          enterFullscreen: 'Volledig scherm openen',
          exitFullscreen: 'Volledig scherm sluiten',
          frameTitle: 'Speler voor {title}',
          captions: 'Ondertiteling',
          settings: 'Instellingen',
          pip: 'PIP',
          menuBack: 'Ga terug naar het vorige menu',
          speed: 'Snelheid',
          normal: 'Normaal',
          quality: 'Kwaliteit',
          loop: 'Lus',
          start: 'Start',
          end: 'Einde',
          all: 'Alles',
          reset: 'Reset',
          disabled: 'Uitgeschakeld',
          enabled: 'Ingeschakeld',
          advertisement: 'Advertentie',
          qualityBadge: {
            2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD',
          },
        },
      });

      player.on('ready', async (event) => {
        const instance = event.detail.plyr;
        const title =
          (Boolean(
            // @ts-ignore
            player.elements.original.getAttribute('data-plyr-show-title'),
          ) &&
            // @ts-ignore
            player.elements.original.getAttribute('data-plyr-title')) ||
          (await getVideoTitle(instance));

        if (title) {
          player.elements.container?.prepend(
            createElement('div', { className: 'plyr__title' }, [
              createElement('h4', { className: 'title__content' }, [title]),
            ]),
          );
        }

        player.elements.container?.classList.add('plyr--ready');
      });
    });
  },
  () => {
    if (!players.length) return;

    while (players.length > 0) {
      const player = players.pop();
      if (player) {
        player.stop();
        player.destroy();
      }
    }
  },
);
