import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  projectsTogglerElements: document.querySelectorAll<HTMLElement>(
    '.content-block--projects .projects__toggler',
  ),
});

const toggleProjects = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const projectsElement = e.currentTarget.closest<HTMLElement>(
    '.projects__project-section',
  );
  if (!projectsElement) return;

  projectsElement.classList.toggle('project-section--expanded');
};

export default defineModule(
  () => {
    const { projectsTogglerElements } = getElements();

    projectsTogglerElements.forEach((projectsTogglerElement) => {
      projectsTogglerElement.addEventListener('click', toggleProjects, {
        passive: true,
      });
    });
  },
  () => {
    const { projectsTogglerElements } = getElements();

    projectsTogglerElements.forEach((projectsTogglerElement) => {
      projectsTogglerElement.removeEventListener('click', toggleProjects);
    });
  },
);
