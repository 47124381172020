import type Plyr from 'plyr';
import { createElement, defineModule } from '../../utils/helpers';

let player: Plyr | null = null;

export default defineModule(
  () => {
    document.addEventListener(
      'open-modal:video',
      async ({ detail: { modalElement, triggerElement } }) => {
        if (!triggerElement) return;

        const videoURL = triggerElement.getAttribute('data-video-url');
        if (!videoURL) return;

        if (
          !player ||
          (player &&
            typeof player.source === 'string' &&
            player.source !== videoURL)
        ) {
          player?.destroy();

          const videoWrapperElement = modalElement.querySelector<HTMLElement>(
            '.modal__content .video_wrapper',
          );
          if (!videoWrapperElement) return;

          const videoElement = createElement('video', {
            src: videoURL,
            playsInline: true,
          });
          videoWrapperElement.replaceChildren(videoElement);

          const { default: Plyr } = await import('plyr');
          player = new Plyr(videoElement);
        }

        player?.play();
      },
    );

    document.addEventListener('dismiss-modal:video', () => player?.pause());
  },
  () => {
    player?.destroy();
    player = null;
  },
);
