import { computePosition, shift, autoUpdate, arrow } from '@floating-ui/dom';
import { createElement, defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
});

const updateSubMenuElementPosition = async (
  menuItem: HTMLLIElement,
  subMenuElement: HTMLUListElement,
) => {
  const arrowElement =
    subMenuElement.querySelector<HTMLSpanElement>('.sub-menu__arrow');

  const { middlewareData, placement, x, y } = await computePosition(
    menuItem,
    subMenuElement,
    {
      placement: 'bottom',
      middleware: [shift(), arrowElement && arrow({ element: arrowElement })],
    },
  );

  Object.assign(subMenuElement.style, {
    left: `${x}px`,
    top: `${y}px`,
  });

  if (middlewareData.arrow && arrowElement) {
    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    Object.assign(arrowElement.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      ...(staticSide && { [staticSide]: '-16px' }),
    });
  }
};

const onMenuItemWithSubMenuEnter = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLLIElement)) return;

  const subMenuElement =
    e.currentTarget.querySelector<HTMLUListElement>('.sub-menu');
  if (!subMenuElement) return;

  await updateSubMenuElementPosition(e.currentTarget, subMenuElement);
};

const autoUpdates: ReturnType<typeof autoUpdate>[] = [];

export default defineModule(
  () => {
    const { navbarElement } = getElements();
    if (!navbarElement) return;

    const menuItemsWithSubMenu = navbarElement.querySelectorAll<HTMLLIElement>(
      '.navbar__nav .menu-item.menu-item-has-children',
    );
    menuItemsWithSubMenu.forEach((menuItem) => {
      const subMenuElement =
        menuItem.querySelector<HTMLUListElement>('.sub-menu');
      if (!subMenuElement) return;

      subMenuElement.appendChild(
        createElement('span', { className: 'sub-menu__arrow' }),
      );

      autoUpdates.push(
        autoUpdate(menuItem, subMenuElement, async () => {
          await updateSubMenuElementPosition(menuItem, subMenuElement);
        }),
      );

      menuItem.addEventListener('mouseenter', onMenuItemWithSubMenuEnter, {
        passive: true,
      });
    });
  },
  () => {
    const { navbarElement } = getElements();
    if (!navbarElement) return;

    const menuItemsWithSubMenu = navbarElement.querySelectorAll<HTMLLIElement>(
      '.navbar__nav .menu-item.menu-item-has-children',
    );
    menuItemsWithSubMenu.forEach((menuItem) => {
      menuItem.removeEventListener('mouseenter', onMenuItemWithSubMenuEnter);
    });

    if (autoUpdates.length) {
      while (autoUpdates.length > 0) {
        autoUpdates.pop()?.();
      }
    }
  },
);
