import { copyToClipboard, defineModule } from '../utils/helpers';

const getElements = () => ({
  copyElements: document.querySelectorAll<HTMLElement>('[data-copy]'),
});

const onCopyElementClick = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const toCopy = e.currentTarget.getAttribute('data-copy');
  if (!toCopy) return;

  await copyToClipboard(toCopy);
};

export default defineModule(
  () => {
    const { copyElements } = getElements();

    copyElements.forEach((copyElement) => {
      copyElement.addEventListener('click', onCopyElementClick, {
        passive: true,
      });
    });
  },
  () => {
    const { copyElements } = getElements();

    copyElements.forEach((copyElement) => {
      copyElement.removeEventListener('click', onCopyElementClick);
    });
  },
);
