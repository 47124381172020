export const nextTick = (callback: Function) => {
  setTimeout(callback, 0);
};

export const createElement = (
  tag: keyof HTMLElementTagNameMap,
  options?: Record<string, any> | null,
  children?: HTMLElement[] | string[] | HTMLCollection,
) => {
  const node = Object.assign(document.createElement(tag), options);
  if (children?.length) node.append(...children);
  return node;
};

/**
 * Define a module (useful when doing something with Swup)
 * @param setup Module setup function
 * @param cleanup Module cleanup function
 * @returns Module object
 */
export const defineModule = (
  setup: () => Promise<void> | void,
  cleanup?: () => Promise<void> | void,
) => ({
  setup,
  cleanup,
});

/**
 * Check if the given variable is a function
 * @param fn Variable to check
 * @returns boolean value based on if `fn` is a function
 */
export const isFunction = (fn: unknown): fn is Function =>
  typeof fn === 'function';

/**
 * Collapse an element to optionally specified height
 * @param element Element to collapse
 * @param height Height to collapse to
 */
export const collapseElement = (
  element: HTMLElement,
  height: number | string = 0,
) => {
  element.style.height = `${element.scrollHeight}px`;
  window.requestAnimationFrame(() => {
    element.style.height = typeof height === 'number' ? `${height}px` : height;
  });
};

/**
 * Expand an element to its full height
 * @param element Element to expand
 */
export const expandElement = (element: HTMLElement) => {
  element.style.height = `${element.scrollHeight}px`;
};

/**
 * Copy provided text to clipboard
 * @param content Text to copy
 * @returns
 */
export const copyToClipboard = async (content: string): Promise<void> => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(content);
    return;
  }

  const temp = createElement('textarea', {
    value: content,
    style: {
      top: 0,
      left: 0,
      position: 'fixed',
    },
  });

  document.body.appendChild(temp);

  temp.focus({ preventScroll: true });
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
};
